import React, { useState } from 'react';
import firebase, { auth, firestore } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-tailwind/react';


const GmailAuth = () => {
  const [isNewUser, setIsNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const provider = new firebase.auth.GoogleAuthProvider();

  const handleGmailLogin = async () => {
    try {
      setIsLoading(true);
      const result = await auth.signInWithPopup(provider);
      const user = result.user;
      const isNewUser = result.additionalUserInfo.isNewUser;
      setIsNewUser(isNewUser);

      if (isNewUser) {
        // Create a new user document in Firestore
        try {
          await firestore.collection('users').doc(user.uid).set({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            credits:20
          });

          setSuccessMessage(
            isNewUser
              ? 'You have been signed up successfully!'
              : 'You have been signed in successfully!'
          );
        } catch (error) {

        }

      }

      // Navigate to the text-to-image route after successful authentication
      navigate('/text-to-image');

    } catch (error) {
      console.error('Error signing in with Gmail:', error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='mt-8 pt-20 flex flex-col items-center justify-center '> 
      <p className="text-center"> 
        You must log in to use our services!
      </p>
      <Button
        variant="text"
        className="flex items-center gap-2 mt-4" 
        onClick={handleGmailLogin}
      >
        SIGN IN/ SIGN UP
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="h-5 w-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
          />
        </svg>
      </Button>
    </div>
  );
};

export default GmailAuth;