import React from 'react';
import Navbar from './Navbar';

const Success = () => (
  <div className='mt-20'>
    <h2 className='pt-10 ml-10'>Payment Successful!</h2>
    <p className='pt-10 ml-10'> Your credits have been added.</p>
  </div>
);

export default Success;
