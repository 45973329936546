import * as React from 'react';

function CreditsPage() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <div className='pt-10 mt-20'>
      <stripe-pricing-table pricing-table-id="prctbl_1PMpc7SBtSEx5oI61sr0jE1i"
publishable-key="pk_test_51P3Wc7SBtSEx5oI6yP2wAAOkpRwmBhLQmI4rQTKsCvOprEHJH7OqPh5YLDY3EZuXERAQQhAANe8J71XZSZNYCvdE00uyN2OjIU">
</stripe-pricing-table>

    </div>
    
  );
}

export default CreditsPage;

