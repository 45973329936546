import React, { useState, useEffect } from 'react';
import AuthButton from './AuthButton';
import { auth, firestore } from '../firebase';
import { Popover, PopoverHandler, PopoverContent } from '@material-tailwind/react';
import CreditsPage from './CreditsPage';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userCredits, setUserCredits] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = firestore.collection('users').doc(user.uid);

        // Fetch initial user data
        try {
          const userDoc = await userRef.get();
          if (userDoc.exists) {
            console.log('Initial user data:', userDoc.data());
            setUserProfile({
              displayName: userDoc.data().displayName,
              email: userDoc.data().email,
              credits: userDoc.data().credits,
            });
            setUserCredits(userDoc.data().credits);
          } else {
            console.error('User document not found');
            setUserProfile(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserProfile(null);
        }

        // Set up Firestore listener for real-time updates
        const unsubscribeFirestore = userRef.onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data();
            console.log('Real-time user data:', data);
            setUserProfile((prevState) => ({
              ...prevState,
              displayName: data.displayName,
              email: data.email,
              credits: data.credits,
            }));
            setUserCredits(data.credits);
          } else {
            console.error('User document not found');
            setUserProfile(null);
          }
        });

        // Clean up Firestore listener on component unmount or when user changes
        return () => {
          console.log('Unsubscribing Firestore listener');
          unsubscribeFirestore();
        };
      } else {
        setUserProfile(null);
        setUserCredits(null);
      }
    });

    // Clean up auth listener on component unmount
    return () => {
      console.log('Unsubscribing auth listener');
      unsubscribeAuth();
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar glass-blur">
      <div className="navbar-container">
        <div className="navbar-brand">Imagenize</div>

        {/* Hamburger Button */}
        <button className="navbar-toggler md:hidden" onClick={toggleMenu}>
          {/* Hamburger button code */}
        </button>

        <ul
          className={`navbar-links md:flex md:mt-0 ${isMenuOpen ? 'fixed top-0 left-0 h-screen w-70 bg-white block' : 'hidden'
            }`}
        >
         <div className="hidden md:ml-6 md:flex md:space-x-8">
              <Link to="/text-to-image" className="py-4 px-6 text-xl font-medium hover:bg-gray-100 md:hover:bg-transparent md:p-0 md:mr-4">
                Home
              </Link>
              <Link to="/credits" className="py-4 px-6 text-xl font-medium hover:bg-gray-100 md:hover:bg-transparent md:p-0 md:mr-4">
                Buy Credits
              </Link>
            </div>
          
          <li className="py-4 px-6 text-xl font-medium hover:bg-gray-100 md:hover:bg-transparent md:p-0 md:mr-4">
            <a href="#about">About</a>
          </li>
          <li className="py-4 px-6 text-xl font-medium hover:bg-gray-100 md:hover:bg-transparent md:p-0 md:mr-4">
            <a href="#contact">Contact</a>
          </li>
         
          <li className="py-4 px-6 text-xl font-medium hover:bg-gray-100 md:hover:bg-transparent md:p-0 md:mr-4">
            <Popover>
              <PopoverHandler>
                <div className="cursor-pointer">My Profile</div>
              </PopoverHandler>
              <PopoverContent className="bg-white rounded-lg shadow-lg p-6">
                {userProfile ? (
                  <div>
                    <h2 className="text-2xl font-bold mb-4">My Profile</h2>
                    <div className="mb-2">
                      <span className="font-medium">Name:</span> {userProfile.displayName}
                    </div>
                    <div className="mb-2">
                      <span className="font-medium">Email:</span> {userProfile.email}
                    </div>
                    <div className="mb-2">
                      <span className="font-medium">Credits:</span> {userProfile.credits}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <span>Loading profile...</span>
                  </div>
                )}
              </PopoverContent>
            </Popover>
          </li>
          <li className="py-4 px-6 text-xl font-medium hover:bg-gray-100 md:hover:bg-transparent md:p-0">
            <AuthButton />
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
