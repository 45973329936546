import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthProvider from './components/AuthContext.jsx';
import GmailAuth from './components/GmailAuth.jsx';
import TextToImage from './components/TextToImage.jsx';
import PrivateRoute from './components/PrivateRoute.jsx';
import AuthButton from './components/AuthButton.jsx';
import Navbar from './components/Navbar.jsx';
import CreditsPage from './components/CreditsPage.jsx';
import Success from './components/Success.jsx';
import Cancel from './components/Cancel.jsx';

const stripePromise = loadStripe('pk_test_51P3Wc7SBtSEx5oI6yP2wAAOkpRwmBhLQmI4rQTKsCvOprEHJH7OqPh5YLDY3EZuXERAQQhAANe8J71XZSZNYCvdE00uyN2OjIU'); // Replace with your Stripe public key

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />        
        <Routes>
          <Route path="/login" element={<GmailAuth />} />
          <Route
            path="/text-to-image"
            element={
              <PrivateRoute>
                <TextToImage />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/credits" element={<CreditsPage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/return" element={<Cancel />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
