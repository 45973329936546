import React, { useContext } from 'react';
import { Button } from '@material-tailwind/react';
import { AuthContext } from './AuthContext';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const AuthButton = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Button 
      variant="text" 
      className="flex items-center gap-2" 
      onClick={handleSignOut}
    >
      {currentUser ? 'Sign Out' : 'Sign In/Sign Up'} {" "} 
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="h-5 w-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
        />
      </svg>
    </Button>
  );
};

export default AuthButton;