import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import FooterWithSitemap from './Footer';
import { Spinner } from "@material-tailwind/react";
// import CreditDeductionComponent from "./CreditDeductionComponent";
import firebase, { auth, firestore } from '../firebase';
import { AuthContext } from './AuthContext';
import useUserData from './useUserData';
import CreditsPage from './CreditsPage';



const API_KEY = process.env.REACT_APP_YOUR_API_KEY;

const API_ENDPOINT =   `https://api.stability.ai/v2beta/stable-image/generate/sd3`;


function TextToImage() {
    const [prompt, setPrompt] = useState('');
    const [aspectRatio, setAspectRatio] = useState('1:1');
    const [negativePrompt, setNegativePrompt] = useState('');
    const [seed, setSeed] = useState('');
    const [outputFormat, setOutputFormat] = useState('png');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [outputImage, setOutputImage] = useState(null);
    const [downloadFile, setDownloadFile] = useState(null);
    const [userCredits, setUserCredits] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const { userProfile, setUserProfile } = useContext(AuthContext);
    const { fetchUserData, deductCredits } = useUserData();

    const currentUser = auth.currentUser;
    const navigate = useNavigate();


    useEffect(() => {

        const fetchUserCredits = async () => {
            try {
                const userDoc = await firestore.collection('users').doc(currentUser.uid).get();
                if (userDoc.exists) {
                    setUserCredits(userDoc.data().credits);
                    setIsUserLoggedIn(true);
                } else {
                    console.error('User document not found');
                    setIsUserLoggedIn(false);
                }
            } catch (error) {
                console.error('Error fetching user credits:', error);
                setIsUserLoggedIn(false);
            }
        };

        fetchUserCredits();
        console.log(fetchUserCredits)
    }, [currentUser]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!prompt.trim()) {
            setErrorMessage('Please enter an image description!');
            return;
        }
        await fetchUserData();

        // Check if the user has enough credits
        if (!userProfile || !userProfile.credits || userProfile.credits < 10) {
            setErrorMessage('Insufficient credits. Please top up your account.');
            
            navigate('/credits');
            return;
        }

        try {
            setIsLoading(true);
            setErrorMessage(null);
            setOutputImage(null);

            const formData = new FormData();
            formData.append('prompt', prompt);
            formData.append('aspect_ratio', aspectRatio);
            formData.append('negative_prompt', negativePrompt);

            if (seed) {
                formData.append('seed', seed);
            }

            formData.append('output_format', outputFormat);

            const response = await axios.post(API_ENDPOINT, formData, {
                headers: {
                    Authorization: `Bearer ${API_KEY}`,
                    Accept: 'image/*'
                },
                responseType: 'arraybuffer'
            });
            console.log("API Response:", response)
            // Handle success, base64 encoding for display
            const arrayBufferView = new Uint8Array(response.data);
            const blob = new Blob([arrayBufferView], { type: 'image/' + outputFormat });
            const imageUrl = URL.createObjectURL(blob);
            const downloadableFile = new File([blob], `image.${outputFormat}`, { type: 'image/' + outputFormat });

            setOutputImage(imageUrl);
            setDownloadFile(downloadableFile);
            setPrompt('');
            setNegativePrompt('');

            await deductCredits(1);


        } catch (error) {
            setErrorMessage('Error generating image. Please check your API key and try again.');
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        let currentImageUrl = outputImage;

        return () => {
            if (currentImageUrl) {
                URL.revokeObjectURL(currentImageUrl);
            }
        };
    }, [outputImage]);

    return (
        <div className="flex min-h-screen bg-gray-100 mt-10">
            <div className="flex-grow flex flex-col bg-white shadow-md rounded-lg overflow-y-auto px-4 py-8 sm:px-6">
                <h1 className="text-2xl font-bold text-center mb-4 mt-10">AI Text to Image</h1>

                <div className="flex flex-col md:flex-row">
                    {/* Form */}
                    <div className="md:w-2/3">
                        <form onSubmit={handleSubmit} className="flex flex-col  space-y-4">
                            <div className="flex flex-col">
                                <label htmlFor="prompt" className="text-sm font-medium mb-2 ">Image Description:</label>
                                <textarea id="prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} className="rounded-lg border border-gray-300 p-2 h-24 resize-none" placeholder='What you wish to see in the output image. A strong, descriptive prompt that clearly defines elements, colors, and subjects will lead to better results.' />
                            </div>

                            <h2>Advanced Options</h2>

                            <div className="flex flex-col space-y-2">
                                <div className="flex items-center space-x-2">
                                    <div className="has-tooltip">
                                        <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                            The aspect ratio of the generated image.
                                        </span>
                                        Aspect Ratio:
                                    </div>
                                    <select
                                        id="aspectRatio"
                                        value={aspectRatio}
                                        onChange={(e) => setAspectRatio(e.target.value)}
                                        className="rounded-lg border border-gray-300 px-3 py-2"
                                    >
                                        <option value="16:9">16:9</option>
                                        <option value="1:1">1:1</option>
                                        <option value="21:9">21:9</option>
                                    </select>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <div className="has-tooltip">
                                        <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                            A blurb of text describing what you do not wish to see in the output image. This is an advanced feature.
                                        </span>
                                        Negative Prompt:
                                    </div>
                                    <textarea
                                        id="negativePrompt"
                                        value={negativePrompt}
                                        onChange={(e) => setNegativePrompt(e.target.value)}
                                        className="rounded-lg border border-gray-300 p-2 h-12 resize-none"
                                    />
                                </div>
                                <div className="flex items-center space-x-2">
                                    <div className="has-tooltip">
                                        <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                            A specific value that is used to guide the 'randomness' of the generation. (Omit this parameter or pass 0 to use a random seed.)
                                        </span>
                                        Seed:
                                    </div>
                                    <input
                                        type="number"
                                        id="seed"
                                        value={seed}
                                        onChange={(e) => setSeed(e.target.value)}
                                        className="rounded-lg border border-gray-300 px-3 py-2 w-24"
                                    />
                                </div>
                                <div className="flex items-center space-x-2">
                                    <div className="has-tooltip">
                                        <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                            The format of the generated image. You can choose from PNG, JPEG, or WebP.
                                        </span>
                                        Output Format:
                                    </div>
                                    <select
                                        id="outputFormat"
                                        value={outputFormat}
                                        onChange={(e) => setOutputFormat(e.target.value)}
                                        className="rounded-lg border border-gray-300 px-3 py-2"
                                    >
                                        <option value="png">PNG</option>
                                        <option value="jpeg">JPEG</option>
                                        <option value="webp">WebP</option>
                                    </select>
                                </div>
                            </div>



                            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg mt-4 flex items-center justify-center">
                                {isLoading ? <Spinner color="amber" className="mr-2" /> : 'Generate Image'}
                            </button>



                            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                        </form>
                    </div>

                    {/* Image Preview */}
                    <div className="md:w-1/3 md:ml-3.5 mt-10">
                        <div className="bg-white shadow-md rounded-lg p-4">
                            <h2 className="text-lg font-bold text-center mb-4">Image Preview</h2>
                            {outputImage ? (
                                <img src={outputImage} alt="Generated Image" className="max-w-full h-auto rounded-md" />
                            ) : (
                                <div className="bg-gray-200 h-64 rounded-md flex items-center justify-center">
                                    <p className="text-gray-500">No image generated yet</p>
                                </div>
                            )}
                            {downloadFile && (
                                <a
                                    href={URL.createObjectURL(downloadFile)}
                                    download={downloadFile.name}
                                    className="mt-4 inline-flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded-md shadow-sm"
                                >
                                    Download Image
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <FooterWithSitemap />
            </div>
        </div>
    );
}

export default TextToImage;