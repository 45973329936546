import { useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext.jsx';
import { auth, firestore } from '../firebase.js';

const useUserData = () => {
  const { currentUser, setUserProfile } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLoading(true);
        try {
          const userDoc = await firestore.collection('users').doc(user.uid).get();

          if (userDoc.exists) {
            setUserProfile({
              displayName: userDoc.data().displayName,
              email: userDoc.data().email,
              credits: userDoc.data().credits,
            });
          } else {
            console.error('User document not found');
            setUserProfile(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserProfile(null);
        } finally {
          setIsLoading(false);
        }
      } else {
        setUserProfile(null);
      }
    });

    return unsubscribe;
  }, [setUserProfile]);

  const fetchUserData = async () => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      setIsLoading(true);
      try {
        const userDoc = await firestore.collection('users').doc(currentUser.uid).get();

        if (userDoc.exists) {
          setUserProfile({
            displayName: userDoc.data().displayName,
            email: userDoc.data().email,
            credits: userDoc.data().credits,
          });
        } else {
          console.error('User document not found');
          setUserProfile(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUserProfile(null);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const deductCredits = async (creditsToDeduct) => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      try {
        const userDoc = await firestore.collection('users').doc(currentUser.uid).get();

        if (userDoc.exists) {
          const currentCredits = userDoc.data().credits;
          const newCredits = Math.max(currentCredits - creditsToDeduct, 0); // Ensure new credits are not negative
          await firestore.collection('users').doc(currentUser.uid).update({ credits: newCredits });

          // Update the user profile
          setUserProfile((prevUserProfile) => ({
            ...prevUserProfile,
            credits: newCredits,
          }));
        } else {
          console.error('User document not found');
        }
      } catch (error) {
        console.error('Error deducting credits:', error);
      }
    }
  };

  return { isLoading, fetchUserData, deductCredits };
};

export default useUserData;