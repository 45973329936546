import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'; 



const firebaseConfig = {

  apiKey: "AIzaSyD9mq_EgO6PJRXPv9PqruI3L2MgLLUGGrY",

  authDomain: "imagenize-new.firebaseapp.com",

  projectId: "imagenize-new",

  storageBucket: "imagenize-new.appspot.com",

  messagingSenderId: "428446168001",

  appId: "1:428446168001:web:ac350d9c761776a0903540",

  measurementId: "G-4116W8JT63"

};


firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export default firebase;


