import React, { createContext, useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);

      if (user) {
        try {
          const userDoc = await firestore.collection('users').doc(user.uid).get();

          if (userDoc.exists) {
            setUserProfile({
              displayName: userDoc.data().displayName,
              email: userDoc.data().email,
              credits: userDoc.data().credits,
            });
          } else {
            console.error('User document not found');
            setUserProfile(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserProfile(null);
        }
      } else {
        setUserProfile(null);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    userProfile,
    setUserProfile
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;